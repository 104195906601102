<template>
  <v-list
    class="pa-0 ma-0 fonte"
    :dark="$theme.dark"
    :light="$theme.light"
    two-line
    dense
    background-color="transparent"
    style="min-height: 20vh; max-height: 90vh; overflow: auto;"
  >
    <template v-for="(plan, i) in showFiltereds">
      <v-list-item
        @click="open(plan)"
        style="border-radius: 6px;"
        :key="plan._id"
        class="fonte pl-2 pr-1 animate__animated animate__fadeInUp"
      >
        <v-list-item-content class="">
          <v-list-item-title class="fonte">
            {{ plan.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $helper.formataSaldo(plan.value_cents) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-avatar rounded size="36" color="#f2f2f2">
            <v-icon :color="$theme.primary">mdi-source-branch</v-icon>
          </v-avatar>
        </v-list-item-action>
      </v-list-item>
      <v-divider v-if="i < showFiltereds.length - 1" :key="i"></v-divider>
    </template>
    <v-list-item
      style="min-height: 200px;"
      v-intersect="onIntersect"
    ></v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    filtereds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      itemsToShow: 12
    };
  },
  computed: {
    ...mapGetters(["get_plan"]),
    showFiltereds() {
      return this.filtereds.slice(0, this.itemsToShow);
    }
  },
  methods: {
    onIntersect() {
      this.itemsToShow += 12;
    },
    open(plan) {
      this.$store.commit("set_plan", plan);
    }
  }
};
</script>
