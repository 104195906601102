<template>
  <div class="expande-horizontal column pt-0">
    <v-flex xs12>
      <div class="windows-style expande-horizontal fonte column">
        <transition name="slide-fade">
          <v-list-item class="pa-0 ma-0">
            <v-avatar
              @click="backToList"
              style="cursor: pointer;"
              size="27"
              :color="$theme.primary"
              class="mr-2"
              icon
            >
              <v-icon color="#f2f2f2">mdi-close</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ get_plan.new ? "Novo plano" : get_plan.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-mini">
                Gerencie os detalhes abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </transition>
      </div>
    </v-flex>
    <div style="min-height: 80vh; max-height: 80vh;" class="windows-style-content expande-horizontal wrap">
      <v-tabs v-model="tabs" :background-color="$theme.primary" dark class="fonte">
        <v-tab>Info do plan</v-tab>
        <!-- <v-tab @click="listplanSales">Compras do plan</v-tab> -->
      </v-tabs>
      <v-flex v-if="tabs == 0" xs12>
        <div class="expande-horizontal wrap">
          <v-flex xs12 md4 class="px-2">
          <v-form ref="form">
            <v-flex class="px-2" xs12>
              <v-text-field
                filled
                dense
                v-model="get_plan.name"
                clearable
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                label="Nome do plano"
                placeholder="ex: Plano 1"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-2" xs12>
              <v-text-field
                filled
                dense
                v-model="get_plan.value_cents"
                clearable
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                label="Preço"
                placeholder="ex: 1000"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-2" xs12>
              <v-text-field
                filled
                dense
                v-model="get_plan.identifier"
                clearable
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                label="Identificador do plano"
                placeholder="ex: lite"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-select
                filled
                label="Ciclo do plano"
                placeholder="Ex: Anual ou Mensal..."
                v-model="get_plan.plan_duration"
                :items="[
                  { descricao: 'Anual', key: 'yearly' },
                  { descricao: 'Mensal', key: 'monthly' }
                ]"
                item-text="descricao"
                item-value="key"
                :rules="[v => !!v || 'Nome é obrigatório']"
              ></v-select>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-select
                filled
                label="Ciclo de Cobrança"
                placeholder="Ex: Anual ou Mensal..."
                v-model="get_plan.demand_by"
                :items="[
                  { descricao: 'Anual', key: 'yearly' },
                  { descricao: 'Mensal', key: 'monthly' },
                  { descricao: 'Não cobrar', key: 'inactive' }
                ]"
                item-text="descricao"
                item-value="key"
                :rules="[v => !!v || 'Nome é obrigatório']"
              ></v-select>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field
                filled
                dense
                label="Trial"
                placeholder="Deixe em branco para pegar o valor padrão"
                type="number"
                v-model="get_plan.trial"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field
                filled
                dense
                label="Ordem"
                placeholder="Ordem do plano no site"
                hint="Apenas 1, 2 ou 3"
                type="number"
                v-model="get_plan.order"
              ></v-text-field>
            </v-flex>
            <!-- <v-flex xs12 class="px-7">
              <v-text-field
                filled
                dense
                label="Limite de lojas que podem ser criadas"
                placeholder="ex: 3"
                hint="Quando não informado, este valor é 1"
                type="number"
                v-model="get_plan.store_limit"
              ></v-text-field>
            </v-flex> -->
          </v-form>
        </v-flex>
        <v-flex xs12 md4 class="px-2">
          <div class="expande-horizontal wrap">
            <v-list color="transparent">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Descricão no site
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    liste as features deste plano como vão aparecer no site.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="(feature, index) in get_plan.feature_description" :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ feature.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="removeFeatureDescription(index)">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-text-field
              filled
              v-model="new_feature_description"
              ref="inputFeatureDescription"
              append-icon="mdi-plus"
              @keyup.enter="addFeatureDescription()"
              @click:append="addFeatureDescription()"
              label="Adicionar nova feature"
            ></v-text-field>
          </div>
        </v-flex>
        <v-flex class="px-2 pt-0" xs12 md4>
          <div
            class="expande-horizontal wrap"
            style="max-height: 78vh; overflow: auto; overflow-x: hidden;"
          >
          <v-flex class="pa-2 pb-3 pt-3" xs12>
            <div class="expande-horizontal column">
              <span class="fonte">
                Features do plano
              </span>
              <span class="grey--text fonte-micro fonte">
                Escolha as features do plano
              </span>
            </div>
          </v-flex>
            <v-flex xs12 class="px-2">
              <v-list
                nav
                color="transparent"
                class="animate__fadeInRight animate__animated pa-0 ma-0 pb-6"
              >
                <template v-for="(feature, index) in get_plan.features">
                  <v-list-item
                    style="background-color: #f5f5f5; border-radius: 6px; margin-bottom: 6px; margin-right: 6px;"
                    :key="`${feature.name}-${index}`"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        class="fonte fonte-mini fonte-bold font-default-color"
                      >
                        {{ feature }}
                      </v-list-item-title>
                      <v-list-item-subtitle></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-select
                filled
                dense
                multiple
                label="Selecione as features do plano"
                placeholder="Ex: Gestor de pedido"
                v-model="get_plan.features"
                :items="features"
                :rules="[v => !!v || 'Nome é obrigatório']"
              ></v-select>
            </v-flex>
          </div>
        </v-flex>
        </div>
      </v-flex>
      <!-- Compras do plan -->
      <v-flex v-if="tabs == 1" xs12>
        <div class="expande-horizontal wrap">
          <h2 class="fonte-bold ml-3">O plan realizou {{ sales.length }} compras.</h2>
          <div style="min-height: 67vh; max-height: 67vh; overflow: auto;" class="expande-horizontal px-3" v-if="sales.length > 0">
            <v-flex xs12 md3
              class="mr-3"
              v-for="sale in sales" :key="sale._id"
            >
              <SaleItem
                :kanban="sale"
              />
            </v-flex>
          </div>
       
          <div class="expande-horizontal centraliza" v-else>
            <span class="my-12 fonte">Este plan ainda não realizou nenhuma compra</span>
          </div>
        </div>
      </v-flex>
    </div>

    <div class="expande-horizontal py-1 windows-style-bottom">
      <v-btn large dark @click="valida_form" :color="$theme.primary">
        <span class="fonte"> Salvar </span>
      </v-btn>
      <!-- <v-btn
        v-if="get_plan._id"
        color="red"
        class="ml-3"
        @click="excluir_plan_dialog(get_plan)"
        text
        >Excluir</v-btn
      > -->
    </div>
  </div>
</template>

<script>
// import SaleItem from "@/apps/GestorDePedido/components/SaleItem.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    // SaleItem
  },
  computed: {
    ...mapGetters(["get_plan", "getLoggedUser"])
  },
  data() {
    return {
      tabs: 0,
      sales: [],
      feature_name: [],
      features: [
        'dashboard',
        'caixa',
        'gestor-de-venda',
        'minha-agenda',
        'agenda',
        'lista-de-compra',
        'produtos',
        'clientes',
        'colaboradores',
        'contas',
        'contabilidade',
        'relatorio-de-contabilidade',
        'permissoes',
        'configuracoes',
        'personalizar-loja'
      ],
      new_feature_description: ""
    }
  },
  methods: {
    ...mapActions([
      "criar_plan",
      "atualizar_plan",
      "fecha_modal_view_plan",
      "enableLoading",
      "disableLoading"
    ]),
    addFeatureDescription() {
      if (this.get_plan.feature_description) {
        this.get_plan.feature_description.push({ name: this.new_feature_description })  
      } else {
        this.get_plan.feature_description = [{ name: this.new_feature_description }];
      }
      this.new_feature_description = "";
      this.$refs.inputFeatureDescription.focus();
    },
    removeFeatureDescription(index) {
      this.get_plan.feature_description.splice(index, 1);
    },
    listplanSales() {
      this.enableLoading();
      this.$run('plans/list-plan-sales', { _id: this.get_plan._id })
        .then(res => {
          this.disableLoading();
          this.sales = res.data
        })
    },
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_plan._id ? this.atualizar_plan() : this.criar_plan();
      }
    },
    backToList() {
      this.$store.commit("set_plan", {});
      this.$forceUpdate();
    }
  }
};
</script>
